import React from "react";
import { Carousel } from "react-bootstrap";

const NoticePopup = ({ data, setShowNotices }) => {
  return (
    <div className="popup">
      <div className="popup-container">
        <div className="popup-container-item">
          <div className="popup-container-item-header">
            <button onClick={() => setShowNotices(false)}>close</button>
          </div>
          <div className="popup-container-item-image">
            <Carousel variant="dark">
              {data.map(({ notice }, index) => (
                <Carousel.Item key={index} interval={10000}>
                  <span>
                    Notice {index + 1} of {data.length}
                  </span>
                  <h3>{notice.title}</h3>
                  <img
                    fetchpriority="high"
                    src={notice.noticeImage.mediaItemUrl}
                    srcSet={notice.noticeImage.srcSet}
                    alt=""
                    placeholder="fade"
                  />
                </Carousel.Item>
              ))}
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoticePopup;
