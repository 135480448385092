import { useQuery } from "@apollo/client";
import { useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Error404 from "../../components/Error404";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import ScrollButton from "../../components/ScrollButton";
import GET_NOTICES from "../../queries/notice/getNotices";
import "../../styles/App.css";
import About from "../About";
import Contact from "../Contact";
import Facilities from "../Facilities";
import Gallery from "../Gallery";
import Home from "../Home";
import NoticePopup from "../Notices";

const App = () => {
  const { data } = useQuery(GET_NOTICES);
  const [showNotices, setShowNotices] = useState(true);

  return (
    <BrowserRouter>
      {data?.notices?.nodes.length > 0 && showNotices && (
        <NoticePopup
          data={data.notices.nodes}
          setShowNotices={setShowNotices}
        />
      )}
      <Navbar />
      <main>
        <Routes>
          <Route index element={<Home />} />
          <Route path="/facilities" element={<Facilities />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="*" element={<Error404 />} />
        </Routes>
        <ScrollButton />
      </main>
      <Footer />
    </BrowserRouter>
  );
};

export default App;
