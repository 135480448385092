import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    // <Container>
    <footer className="footer">
      <section className="footer__section">
        <Row>
          <Col md={6} lg={4}>
            <div className="footer__item">
              {/* <div className="footer__item--content"> */}
              <h3>Quick Links</h3>
              <ul>
                <li>
                  <Link to="/" className="react-link">
                    Home
                  </Link>
                </li>
                <li>
                  <Link to="/gallery" className="react-link">
                    Gallery
                  </Link>
                </li>
                <li>
                  <Link to="/facilities" className="react-link">
                    Facilities
                  </Link>
                </li>
                <li>
                  <Link to="/about" className="react-link">
                    About Us
                  </Link>
                </li>
                <li>
                  <Link to="/contact" className="react-link">
                    Contact Us
                  </Link>
                </li>
              </ul>
              {/* </div> */}
            </div>
          </Col>
          <Col md={6} lg={4}>
            <div className="footer__item">
              <h3>Contact Us</h3>
              <ul>
                <li>
                  <p className="footer__item--contact">
                    <svg
                      width="22"
                      height="22"
                      viewBox="0 0 22 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.25 5.75C1.25 14.034 7.966 20.75 16.25 20.75H18.5C19.0967 20.75 19.669 20.5129 20.091 20.091C20.5129 19.669 20.75 19.0967 20.75 18.5V17.128C20.75 16.612 20.399 16.162 19.898 16.037L15.475 14.931C15.035 14.821 14.573 14.986 14.302 15.348L13.332 16.641C13.05 17.017 12.563 17.183 12.122 17.021C10.4849 16.4191 8.99815 15.4686 7.76478 14.2352C6.53141 13.0018 5.58087 11.5151 4.979 9.878C4.817 9.437 4.983 8.95 5.359 8.668L6.652 7.698C7.015 7.427 7.179 6.964 7.069 6.525L5.963 2.102C5.90214 1.85869 5.76172 1.6427 5.56405 1.48834C5.36638 1.33397 5.1228 1.25008 4.872 1.25H3.5C2.90326 1.25 2.33097 1.48705 1.90901 1.90901C1.48705 2.33097 1.25 2.90326 1.25 3.5V5.75Z"
                        fill="white"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <span>071417053</span>
                  </p>
                </li>
                <li>
                  <p className="footer__item--contact">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M11.54 22.351L11.61 22.391L11.638 22.407C11.749 22.467 11.8733 22.4985 11.9995 22.4985C12.1257 22.4985 12.25 22.467 12.361 22.407L12.389 22.392L12.46 22.351C12.8511 22.1191 13.2328 21.8716 13.604 21.609C14.5651 20.9305 15.463 20.1667 16.287 19.327C18.231 17.337 20.25 14.347 20.25 10.5C20.25 8.31196 19.3808 6.21354 17.8336 4.66637C16.2865 3.11919 14.188 2.25 12 2.25C9.81196 2.25 7.71354 3.11919 6.16637 4.66637C4.61919 6.21354 3.75 8.31196 3.75 10.5C3.75 14.346 5.77 17.337 7.713 19.327C8.53664 20.1667 9.43427 20.9304 10.395 21.609C10.7666 21.8716 11.1485 22.1191 11.54 22.351ZM12 13.5C12.7956 13.5 13.5587 13.1839 14.1213 12.6213C14.6839 12.0587 15 11.2956 15 10.5C15 9.70435 14.6839 8.94129 14.1213 8.37868C13.5587 7.81607 12.7956 7.5 12 7.5C11.2044 7.5 10.4413 7.81607 9.87868 8.37868C9.31607 8.94129 9 9.70435 9 10.5C9 11.2956 9.31607 12.0587 9.87868 12.6213C10.4413 13.1839 11.2044 13.5 12 13.5Z"
                        fill="white"
                      />
                    </svg>
                    <span>Rudrapur, Nepal</span>
                  </p>
                </li>
                <li>
                  <p className="footer__item--contact">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_45_2125)">
                        <path
                          d="M2.29961 9.50798C2.18116 9.57318 2.08253 9.66918 2.01417 9.78584C1.94581 9.90249 1.91025 10.0355 1.91127 10.1707C1.91228 10.3059 1.94983 10.4383 2.01993 10.5539C2.09004 10.6695 2.19009 10.764 2.30951 10.8274L9.61887 14.6974L15.6406 8.67572C15.7812 8.53507 15.972 8.45605 16.1709 8.45605C16.3698 8.45605 16.5606 8.53507 16.7013 8.67572C16.8419 8.81637 16.9209 9.00714 16.9209 9.20605C16.9209 9.40496 16.8419 9.59573 16.7013 9.73638L10.6795 15.7581L14.5495 23.0675C14.6129 23.1869 14.7075 23.2869 14.8231 23.357C14.9387 23.4271 15.0711 23.4647 15.2063 23.4657C15.3415 23.4667 15.4745 23.4312 15.5911 23.3628C15.7078 23.2944 15.8038 23.1958 15.869 23.0774C19.1862 17.0396 21.4482 10.4798 22.5575 3.68072C22.5767 3.56328 22.5677 3.44295 22.5312 3.32967C22.4948 3.2164 22.4318 3.11344 22.3477 3.02929C22.2635 2.94514 22.1606 2.88223 22.0473 2.84574C21.934 2.80925 21.8137 2.80025 21.6963 2.81946C14.8971 3.9287 8.33733 6.19071 2.29961 9.50798Z"
                          fill="white"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_45_2125">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    <span>visionmodern21@gmail.com</span>
                  </p>
                </li>
              </ul>
              {/* </div> */}
            </div>
          </Col>
          <Col md={6} lg={4}>
            <div className="footer__item">
              {/* <div className="footer__item--content"> */}
              <h3>Socials</h3>
              <ul>
                <li>
                  <a
                    className="footer__item--content-social"
                    href="https://www.facebook.com/profile.php?id=100068041276019"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <div>
                      <svg
                        width="21"
                        height="20"
                        viewBox="0 0 21 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0.00195312 10.002C0.00311667 14.9214 3.58036 19.1101 8.43895 19.881V12.892H5.90195V10.002H8.44195V7.80204C8.32841 6.75958 8.68447 5.72064 9.41359 4.96698C10.1427 4.21332 11.1693 3.82306 12.215 3.90204C12.9655 3.91417 13.7141 3.98101 14.455 4.10205V6.56104H13.191C12.7558 6.50405 12.3183 6.64777 12.0017 6.95171C11.6851 7.25566 11.5237 7.68693 11.563 8.12404V10.002H14.334L13.891 12.893H11.563V19.881C16.8174 19.0506 20.502 14.2518 19.9475 8.96112C19.3929 3.67041 14.7932 -0.260027 9.48078 0.0172227C4.16831 0.294472 0.0027976 4.68235 0.00195312 10.002Z"
                          fill="white"
                        />
                      </svg>
                      <span>Facebook</span>
                    </div>
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.498276 9.94253C0.190394 10.2196 0.165435 10.6938 0.442529 11.0017C0.719624 11.3096 1.19384 11.3346 1.50172 11.0575L0.498276 9.94253ZM11.749 1.53942C11.7707 1.12578 11.4531 0.772807 11.0394 0.751037L4.29875 0.396264C3.88511 0.374494 3.53214 0.692168 3.51037 1.10581C3.4886 1.51945 3.80627 1.87242 4.21991 1.89419L10.2116 2.20954L9.89626 8.20125C9.87449 8.61489 10.1922 8.96786 10.6058 8.98963C11.0194 9.0114 11.3724 8.69373 11.3942 8.28009L11.749 1.53942ZM1.50172 11.0575L11.5017 2.05747L10.4983 0.942529L0.498276 9.94253L1.50172 11.0575Z"
                        fill="white"
                      />
                    </svg>
                  </a>
                </li>
                <li>
                  <a
                    className="footer__item--content-social"
                    href="https://www.facebook.com/profile.php?id=100068041276019"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <div>
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10 18H6V6H10V8C10.8526 6.91525 12.1456 6.26857 13.525 6.237C16.0056 6.25077 18.0072 8.26938 18 10.75V18H14V11.25C13.84 10.1326 12.8818 9.30358 11.753 9.306C11.2593 9.32161 10.7932 9.53777 10.4624 9.90462C10.1316 10.2715 9.96464 10.7573 10 11.25V18ZM4 18H0V6H4V18ZM2 4C0.89543 4 0 3.10457 0 2C0 0.89543 0.89543 0 2 0C3.10457 0 4 0.89543 4 2C4 2.53043 3.78929 3.03914 3.41421 3.41421C3.03914 3.78929 2.53043 4 2 4Z"
                          fill="white"
                        />
                      </svg>

                      <span>Linkedin</span>
                    </div>
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.498276 9.94253C0.190394 10.2196 0.165435 10.6938 0.442529 11.0017C0.719624 11.3096 1.19384 11.3346 1.50172 11.0575L0.498276 9.94253ZM11.749 1.53942C11.7707 1.12578 11.4531 0.772807 11.0394 0.751037L4.29875 0.396264C3.88511 0.374494 3.53214 0.692168 3.51037 1.10581C3.4886 1.51945 3.80627 1.87242 4.21991 1.89419L10.2116 2.20954L9.89626 8.20125C9.87449 8.61489 10.1922 8.96786 10.6058 8.98963C11.0194 9.0114 11.3724 8.69373 11.3942 8.28009L11.749 1.53942ZM1.50172 11.0575L11.5017 2.05747L10.4983 0.942529L0.498276 9.94253L1.50172 11.0575Z"
                        fill="white"
                      />
                    </svg>
                  </a>
                </li>
                <li>
                  <a
                    className="footer__item--content-social"
                    href="https://www.facebook.com/profile.php?id=100068041276019"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <div>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M19.995 6.68799C20.8914 6.15208 21.5622 5.30823 21.882 4.31399C21.0397 4.81379 20.118 5.16587 19.157 5.35499C17.8246 3.94552 15.7135 3.60251 14.0034 4.51764C12.2933 5.43277 11.4075 7.37948 11.841 9.26999C8.39062 9.09676 5.17598 7.4669 2.99702 4.78599C1.85986 6.74741 2.44097 9.25477 4.32502 10.516C3.64373 10.4941 2.97754 10.3096 2.38202 9.97799C2.38202 9.99599 2.38202 10.014 2.38202 10.032C2.38241 12.0751 3.82239 13.8351 5.82502 14.24C5.19308 14.4119 4.53022 14.4372 3.88702 14.314C4.45022 16.0613 6.06057 17.2583 7.89602 17.294C6.37585 18.4871 4.49849 19.1342 2.56602 19.131C2.22349 19.1315 1.88123 19.1118 1.54102 19.072C3.50341 20.333 5.78738 21.0023 8.12002 21C11.3653 21.0223 14.484 19.7429 16.7787 17.448C19.0734 15.1531 20.3526 12.0342 20.33 8.78899C20.33 8.60299 20.3257 8.41799 20.317 8.23399C21.1575 7.62659 21.8828 6.87414 22.459 6.01199C21.676 6.35905 20.8455 6.58691 19.995 6.68799Z"
                          fill="white"
                        />
                      </svg>

                      <span>Twitter</span>
                    </div>
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.498276 9.94253C0.190394 10.2196 0.165435 10.6938 0.442529 11.0017C0.719624 11.3096 1.19384 11.3346 1.50172 11.0575L0.498276 9.94253ZM11.749 1.53942C11.7707 1.12578 11.4531 0.772807 11.0394 0.751037L4.29875 0.396264C3.88511 0.374494 3.53214 0.692168 3.51037 1.10581C3.4886 1.51945 3.80627 1.87242 4.21991 1.89419L10.2116 2.20954L9.89626 8.20125C9.87449 8.61489 10.1922 8.96786 10.6058 8.98963C11.0194 9.0114 11.3724 8.69373 11.3942 8.28009L11.749 1.53942ZM1.50172 11.0575L11.5017 2.05747L10.4983 0.942529L0.498276 9.94253L1.50172 11.0575Z"
                        fill="white"
                      />
                    </svg>
                  </a>
                </li>
              </ul>
              {/* </div> */}
            </div>
          </Col>
        </Row>
      </section>
    </footer>
    // </Container>
  );
};

export default Footer;
