import React from "react";
import { Container } from "react-bootstrap";
import TestimonialCard from "../../components/TestimonialCard";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import saugat from "../../assets/img/saugat.png";
import durlav from "../../assets/img/durlav.png";
import ravi from "../../assets/img/ravi.png";
import sujata from "../../assets/img/sujata.png";
import yuken from "../../assets/img/yuken.png";

const testimonials_data = [
  {
    img: saugat,
    name: "Saugat Dhakal",
    title: "Ex-student",
    commnets:
      "What I am today, how I managed to be here, all credits to my school from where I completed my basic level education and SEE. This school gave me everything I needed, wanted and always backed me up. I will try my best to make the name 'Modern Vision Academy' renowned wherever I go.",
  },
  {
    img: ravi,
    name: "Ravi Sharma",
    title: "Ex-student",
    commnets:
      "I am proud to be a student at Modern Vision Academy. The teachers here go above and beyond to ensure each student's success, the curriculum is challenging and prepares us for the future, and the variety of extracurricular activities allows us to explore our passions.",
  },
  {
    img: yuken,
    name: "Yuken Sunar",
    title: "Ex-student",
    commnets:
      "My heart leaps up when flip through the beautiful memories at MVA, inspiring words from the friendly teachers and of course a beautiful environment to make us more voracious and better every time. I would like to acknowledge every faculty and all those helping hands who went the extra miles to achieve this result.",
  },
  {
    img: durlav,
    name: "Durlav  Parajuli",
    title: "Ex-student",
    commnets:
      "Whether you are a student seeking academic excellence or simply looking for a positive and engaging school environment, this school is the perfect choice. It is a place where students can thrive, grow, and reach their full potential.",
  },
  {
    img: sujata,
    name: "Sujata Khanal",
    title: "Ex-student",
    commnets:
      "From painting my mind to guiding my thoughts, Sharing my achievements to advising my faults, From inspiring me to share knowledge and truth, From enduring me to work hard and to reach From allowing me to dream and soar like a kite, Thank you for being my friend, mentor & light Modern Vision Academy.",
  },
];

export default function HomeTestimonial() {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 1,
    },
  };
  return (
    <div className="Home_testimonials">
      <Container>
        <h2>Testimonials</h2>
        <p>Here is some what our students and parents has to say about us.</p>
        <div className="Home_testimonials--cards">
          <Carousel responsive={responsive} infinite={true}>
            {testimonials_data.map((item, i) => {
              return (
                <TestimonialCard
                  key={i}
                  className="website"
                  Title={item.title}
                  Comments={item.commnets}
                  Name={item.name}
                  img_url={item.img}
                />
              );
            })}
          </Carousel>
          {/* <div className="slider-controllers">
            <div className="sliders-dots">
              <svg
                width="13"
                height="12"
                viewBox="0 0 13 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="6.5" cy="6" r="5.5" stroke="black" />
              </svg>
              <svg
                width="13"
                height="12"
                viewBox="0 0 13 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="6.5" cy="6" r="5.5" stroke="black" />
              </svg>
              <svg
                width="13"
                height="12"
                viewBox="0 0 13 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="6.5" cy="6" r="5.5" stroke="black" />
              </svg>
              <svg
                width="13"
                height="12"
                viewBox="0 0 13 12"
                fill="black"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="6.5" cy="6" r="5.5" stroke="black" />
              </svg>
            </div>
            <div className="slider-controller">
              <button className="backward">
                <svg
                  width="60"
                  height="59"
                  viewBox="0 0 60 59"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M29.9999 6.76013C17.4427 6.76013 7.26028 16.9401 7.26028 29.4997C7.26028 42.0569 17.4427 52.2393 29.9999 52.2393C42.557 52.2393 52.7394 42.0569 52.7394 29.4997C52.7394 16.9401 42.557 6.76013 29.9999 6.76013Z"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M33.5457 20.9652L24.976 29.4981L33.5457 38.031"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
              <button className="forward">
                <svg
                  width="60"
                  height="59"
                  viewBox="0 0 60 59"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M30.0001 52.2399C42.5573 52.2399 52.7397 42.0599 52.7397 29.5003C52.7397 16.9431 42.5573 6.7607 30.0001 6.7607C17.443 6.7607 7.26056 16.9431 7.26056 29.5003C7.26056 42.0599 17.443 52.2399 30.0001 52.2399Z"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M26.4543 38.0348L35.024 29.5019L26.4543 20.969"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>
          </div> */}
        </div>
      </Container>
    </div>
  );
}
