import React from "react";
import { Container, Row, Col } from "react-bootstrap";

export default function Facilites1({ img, title, description }) {
  return (
    <div>
      <Container>
        <Row>
          <Col lg={6}>
            <img
              src={img}
              width="100%"
              height="auto"
              alt=""
              style={{ borderRadius: "16px" }}
            />
          </Col>
          <Col lg={6}>
            <div className="facilities_one-content-wrapper">
              <Container>
                <h2>{title}</h2>
                <p>{description}</p>
              </Container>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
