import school from "../../assets/img/school.png";
import Banner from "../Banner";

const AboutBanner = () => {
  return (
    <Banner
      bannerImg={school}
      title="Modern Vision Academy"
      description="A place for holistic development of your children."
    />
  );
};

export default AboutBanner;
