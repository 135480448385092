import { Container } from "react-bootstrap";

const Banner = ({bannerImg,title,description}) => {
  return (
    <Container>
      <figure className="banner">
        <img src={bannerImg} alt="Banner" />
        <div className="banner--info">
          <h1>{title}</h1>
          <span>{description}</span>
        </div>
      </figure>
    </Container>
  );
};

export default Banner;
