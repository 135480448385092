import React from "react";
import { useQuery } from "@apollo/client";

const Query = ({
  children,
  query,
  slug,
  id,
  sort,
  notin,
  loader = <p>Loading...</p>,
}) => {
  const { data, loading, error } = useQuery(query, {
    variables: { slug: slug, id: id, sort: sort, notin: notin },
  });

  if (loading) {
    return loader;
  }

  if (error) {
    console.log(error);
    return <p>Some error occured</p>;
  }
  return children({ data });
};

export default Query;
