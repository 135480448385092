import { gql } from "@apollo/client";

const GET_NOTICES = gql`
  query getNotices {
    notices(first: 1000) {
      nodes {
        slug
        notice {
          title
          noticeImage {
            sourceUrl
            srcSet
          }
        }
      }
    }
  }
`;

export default GET_NOTICES;
