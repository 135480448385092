import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
// import error404 from "../../assets/img/error404.png";

const Error404 = () => {
  return (
    <section className="error">
      <Container>
        <figure>
          <img src="./404.svg" width="50%" alt="Error 404" />
        </figure>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Link to="/">
            <button className="bton--primary">Go to Home</button>
          </Link>
        </div>
      </Container>
    </section>
  );
};

export default Error404;
