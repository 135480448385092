import { Col, Container, Row } from "react-bootstrap";

const AboutVision = () => {
  return (
    <section className="about__vision">
      <Container>
        <Row>
          <Col lg={6}>
            <div className="about__vision--item">
              <svg
                width="68"
                height="72"
                viewBox="0 0 68 72"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M65.5 3L62 6.5M2.5 3L6 6.5M65.5 52L62 48.5M2.5 52L6 48.5M23.5 59H44.5M27 69.5H41M34 6.5C20 6.5 12.832 13.325 13 24C13.0805 29.2045 14.75 32.75 18.25 36.25C21.75 39.75 23.5 41.5 23.5 48.5H44.5C44.5 41.5 46.25 39.75 49.75 36.25C53.25 32.75 54.9195 29.2045 55 24C55.168 13.325 48 6.5 34 6.5Z"
                  stroke="#2956C9"
                  strokeWidth="4.44"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <h2>Our Vision</h2>
              <p>
                In pursuit of our vision, we aim to cultivate well balanced
                citizens and a sound positive nationalism in upholding universal
                ideas in the heritage of Nepalese people. "Every child has
                potential and every child is special. "Child's I can" is much
                more powerful than IQ. Every child has a right to learn with joy
                and confidence.Every child is unique. Pay attention to
                stimulating intellectual curiosity. The principle of teaching is
                not to be an instructor or lecturer, he is a facilitator and a
                guide. Global well being with best practices in education and
                indulcating, universal values is the exxence.
              </p>
            </div>
          </Col>
          <Col lg={6}>
            <div className="about__vision--item">
              <svg
                width="52"
                height="78"
                viewBox="0 0 52 78"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M36.141 33.4276L49.4997 3.16638M18.382 34.0977L2.91638 3.16638M30.7266 32.005L16.6585 3.16638M36.4564 3.16638L32.7297 12.1247M6.49972 53.333C6.49972 59.0352 8.76489 64.5038 12.7969 68.5358C16.829 72.5679 22.2976 74.833 27.9997 74.833C33.7019 74.833 39.1705 72.5679 43.2025 68.5358C47.2345 64.5038 49.4997 59.0352 49.4997 53.333C49.4997 47.6309 47.2345 42.1623 43.2025 38.1303C39.1705 34.0982 33.7019 31.833 27.9997 31.833C22.2976 31.833 16.829 34.0982 12.7969 38.1303C8.76489 42.1623 6.49972 47.6309 6.49972 53.333Z"
                  stroke="#2956C9"
                  strokeWidth="4.44333"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <h2>Our Mission</h2>
              <p>
                At Modern Vision Academy, we prepare students to understand,
                contribute and succeed in the society , by making the world a
                better place. We ensure that our students develop both the
                skills and sound education. We provide: Best Results, Top Ranks
                in the region, Joyful learning, Prioritizes humanity and
                culture. Seeing society by providing skilled, learned and
                compassionate human beings with values is the abstract .
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default AboutVision;
