import { Fragment } from "react";
import { Link } from "react-router-dom";

const NavMobile = ({ onClick }) => {
  return (
    <Fragment>
      <section className="nav-mobile__section" onClick={onClick} />
      <nav className="nav-mobile">
        <svg
          className="close"
          viewBox="0 0 16 16"
          fill="none"
          onClick={onClick}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.64604 4.64592C4.69248 4.59935 4.74766 4.56241 4.80841 4.5372C4.86915 4.512 4.93427 4.49902 5.00004 4.49902C5.06581 4.49902 5.13093 4.512 5.19167 4.5372C5.25242 4.56241 5.30759 4.59935 5.35404 4.64592L8.00004 7.29292L10.646 4.64592C10.6925 4.59943 10.7477 4.56255 10.8085 4.53739C10.8692 4.51223 10.9343 4.49929 11 4.49929C11.0658 4.49929 11.1309 4.51223 11.1916 4.53739C11.2524 4.56255 11.3076 4.59943 11.354 4.64592C11.4005 4.6924 11.4374 4.74759 11.4626 4.80833C11.4877 4.86907 11.5007 4.93417 11.5007 4.99992C11.5007 5.06566 11.4877 5.13076 11.4626 5.1915C11.4374 5.25224 11.4005 5.30743 11.354 5.35392L8.70704 7.99992L11.354 10.6459C11.4005 10.6924 11.4374 10.7476 11.4626 10.8083C11.4877 10.8691 11.5007 10.9342 11.5007 10.9999C11.5007 11.0657 11.4877 11.1308 11.4626 11.1915C11.4374 11.2522 11.4005 11.3074 11.354 11.3539C11.3076 11.4004 11.2524 11.4373 11.1916 11.4624C11.1309 11.4876 11.0658 11.5005 11 11.5005C10.9343 11.5005 10.8692 11.4876 10.8085 11.4624C10.7477 11.4373 10.6925 11.4004 10.646 11.3539L8.00004 8.70692L5.35404 11.3539C5.30755 11.4004 5.25236 11.4373 5.19162 11.4624C5.13088 11.4876 5.06578 11.5005 5.00004 11.5005C4.9343 11.5005 4.86919 11.4876 4.80846 11.4624C4.74772 11.4373 4.69253 11.4004 4.64604 11.3539C4.59955 11.3074 4.56267 11.2522 4.53752 11.1915C4.51236 11.1308 4.49941 11.0657 4.49941 10.9999C4.49941 10.9342 4.51236 10.8691 4.53752 10.8083C4.56267 10.7476 4.59955 10.6924 4.64604 10.6459L7.29304 7.99992L4.64604 5.35392C4.59948 5.30747 4.56253 5.2523 4.53733 5.19155C4.51212 5.13081 4.49915 5.06568 4.49915 4.99992C4.49915 4.93415 4.51212 4.86903 4.53733 4.80828C4.56253 4.74754 4.59948 4.69236 4.64604 4.64592Z"
            fill="black"
          />
        </svg>

        <ul className="nav-mobile__list">
          <Link to="/" onClick={onClick}>
            <li className="nav-mobile__list--item">Home</li>
          </Link>
          <Link to="/facilities" onClick={onClick}>
            <li className="nav-mobile__list--item">Facilites</li>
          </Link>
          <Link to="/about" onClick={onClick}>
            <li className="nav-mobile__list--item">About</li>
          </Link>
          <Link to="/gallery" onClick={onClick}>
            <li className="nav-mobile__list--item">Gallery</li>
          </Link>
          <Link to="/contact" onClick={onClick}>
            <li className="nav-mobile__list--item">Contact</li>
          </Link>
        </ul>
      </nav>
    </Fragment>
  );
};

export default NavMobile;
