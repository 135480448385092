import React, { useEffect } from "react";
import AboutVision from "../../components/About/AboutVision";
import HeroSection from "./HeroSection";
import HomeTestimonial from "./HomeTestimonial";
import HomeAbout from "./HomeAbout";
import HomeFacilities from "./HomeFacilities";
import IsoCertification from "./IsoCertification";
import Whyus from "./Whyus";

export default function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="home">
      <HeroSection />
      <HomeAbout />
      <IsoCertification />
      <Whyus />
      <HomeFacilities />
      <AboutVision />
      <HomeTestimonial />
    </div>
  );
}
