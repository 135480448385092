import { Col, Container, Row } from 'react-bootstrap';
import founderchairperson from '../../assets/img/founderchairperson.png';
import chairperson from '../../assets/img/chairperson.png';
import managingDirector from '../../assets/img/managingDirector.png';
import shareholder3 from '../../assets/img/shareholder3.png';
import shareholder4 from '../../assets/img/shareholder4.png';
import shareholder5 from '../../assets/img/shareholder5.png';
import shareholder6 from '../../assets/img/shareholder6.png';
import shareholder7 from '../../assets/img/shareholder7.png';
import shareholder8 from '../../assets/img/shareholder8.png';
import shareholder9 from '../../assets/img/shareholder9.png';
import manager1 from '../../assets/img/manager1.png';
import manager2 from '../../assets/img/manager2.png';
import manager3 from '../../assets/img/manager3.png';
import manager4 from '../../assets/img/manager4.png';
import manager5 from '../../assets/img/manager5.png';

const shareHolders = [
  {
    img: founderchairperson,
    name: 'Mr. Nagendra Lamichhane',
    post: 'Founder Chairperson',
  },
  {
    img: chairperson,
    name: 'Mr. Lal Prasad Sharma',
    post: 'Chairperson',
  },
  {
    img: shareholder9,
    name: 'Mr. Krishna Prasad Jaishi',
    post: '',
  },
  {
    img: shareholder3,
    name: 'Mr Meghnath Sharma',
    post: '',
  },
  {
    img: shareholder4,
    name: 'Mr. Prakash Acharya',
    post: '',
  },
  {
    img: shareholder5,
    name: 'Mr. Gokarna Bahadur Chhetri',
    post: '',
  },
  {
    img: shareholder6,
    name: 'Mr. Puspa Raj Paudel',
    post: 'Ex-Managing Director',
  },
  {
    img: shareholder7,
    name: 'Mr. Thakumar Jaishi',
    post: '',
  },
  {
    img: managingDirector,
    name: 'Mr. Surendra Prasad Subedi',
    post: 'Managing Director',
  },
  {
    img: shareholder8,
    name: 'Mr. Somnath Paudel',
    post: '',
  },
];

const managers = [
  {
    img: chairperson,
    name: 'Mr. Lal Prasad Sharma',
    post: 'Chairperson',
  },
  {
    img: founderchairperson,
    name: 'Mr. Nagendra Bahadur Lamichhane',
    post: 'Founder Chairperson',
  },
  {
    img: managingDirector,
    name: 'Surendra Pd Subedi',
    post: 'Managing Director',
  },
  {
    img: shareholder7,
    name: 'Mr. Thakumar Jaishi',
    post: '',
  },
  {
    img: shareholder8,
    name: 'Mr. Somnath Paudel',
    post: '',
  },
  {
    img: manager1,
    name: 'Mrs. Lilawati Adhikari',
    post: '',
  },
  {
    img: manager2,
    name: 'Mrs. Sushila Neupane',
    post: '',
  },
  {
    img: manager3,
    name: 'Mrs. Santoshi Sharma',
    post: '',
  },
  {
    img: manager4,
    name: 'Mrs. Krishna Kumari Sharma',
    post: '',
  },
  {
    img: manager5,
    name: 'Mrs. Geeta Devi Jaishi',
    post: '',
  },
];

export const MemberCard = ({ name, post, img }) => {
  return (
    <Col sm={6} lg={3}>
      <div className='about__management--item'>
        <figure>
          <img src={img} alt='Management committe' />
        </figure>
        <h3 className='about__management--item_name'>{name}</h3>
        <h4 className='about__management--item_post'>{post}</h4>
      </div>
    </Col>
  );
};

const ManagementCommitte = () => {
  return (
    <Container>
      <section className='about__management'>
        <h2>School Management Committe</h2>
        <Row>
          {managers.map((member, i) => {
            return (
              <MemberCard
                key={i}
                name={member.name}
                post={member.post}
                img={member.img}
              />
            );
          })}
        </Row>
      </section>
      <section className='about__management'>
        <h2>Our Partners</h2>
        <Row>
          {shareHolders.map((member, i) => {
            return (
              <MemberCard
                key={i}
                name={member.name}
                post={member.post}
                img={member.img}
              />
            );
          })}
        </Row>
      </section>
    </Container>
  );
};

export default ManagementCommitte;
