import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import school from '../../assets/img/classroom1.png';

export default function HomeAbout() {
  return (
    <div className='about-wrapper'>
      <Container>
        <Row>
          <Col lg={6} className='about-content-wrapper'>
            <h2>About Us</h2>
            <p>
              We are one of the Best school among public as well as in private
              sector in Kanchan Rural Municipality that spreads in more than 1
              acre land area. We provide lush green noise free environment for
              quality education. Ever since the dearth of a standard quality
              education- oriented school was felt, our partners in the
              leadership of Founder Chair Person decided to establish an English
              medium school with affordable fee structure with highly esteemed
              noble visions of uplifting the intellectual status of the kids of
              this remote area in 2066 BS (2010 AD).
              <br />
              In the beginning the school started giving education up to basic
              level and then since 2074 BS it has been imparting education up to
              SEE level making the guardians of this area relieved from sending
              their small kids to the town / city for quality education.
              <br />
              Since then the school has not left any stone unturned to maintain
              its standard of quality. Guardians are fully satisfied with the
              all-round progress of their offspring. The quest of quality
              education is fulfilled now. Excellent output is now our habit.
              <br />
              We are always eager to welcome the guardians and kids willing to
              get quality education in child friendly environment among well
              trained, dedicated and self-motivated teachers.
              <br />
            </p>
          </Col>
          <Col lg={6}>
            <img
              src={school}
              width='100%'
              height='100%'
              style={{ objectFit: 'cover', borderRadius: '10px' }}
              alt=''
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
