import React from "react";

export default function WhyusCard({ img, title, description }) {
  return (
    <div className="why-us-card">
      <figure>
        <img src={img} alt="school" />
      </figure>
      <div className="whyus-content">
        <h3>{title}</h3>
        <p>{description}</p>
      </div>
    </div>
  );
}
