import React from "react";

export default function TestimonialCard({ img_url, Title, Comments, Name }) {
  return (
    <div className="testimonial-card">
      <div>
        <figure>
          <img src={img_url} width={"100%"} alt="student" />
        </figure>
        <p>{Comments}</p>
      </div>
      <div className="testimonials-detail">
        <h4>{Name}</h4>
        <span>{Title}</span>
      </div>
    </div>
  );
}
