import { gql } from "@apollo/client";

const GET_GALLERY = gql`
  query getGalley {
    galleries(first: 1000) {
      nodes {
        slug
        galleyGroup {
          title
          gallery {
            sourceUrl
            srcSet
          }
        }
      }
    }
  }
`;

export default GET_GALLERY;
