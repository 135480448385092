import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import SecondaryButton from "../../components/Secoundary_button";

export default function HomeFacilities() {
  return (
    <div className="home_facilities">
      <Container>
        <Row>
          <Col lg={6}>
            <img src="./facilites.png" width="100%" height="auto" alt="" />
          </Col>
          <Col lg={6}>
            <Container>
              <div className="home_facilities-content-wrapper">
                <h2>Our Facilites</h2>
                <p>
                  We at Modern Vision Believe that student can grow and learn
                  the most student in better environment with better facilities.
                  Therefore we offer all the features require for the student to
                  grow organically.
                </p>

                <Link to="/facilities">
                  {" "}
                  <SecondaryButton button_copy={"See our Facilites"} />
                </Link>
              </div>
            </Container>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
