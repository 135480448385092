import { Col } from "react-bootstrap";

const GalleryCard = ({ image }) => {
  return (
    <Col sm={6} md={4} lg={3}>
      <figure className="gallery__grid--img">
        <img src={image} alt="Gallery" />
      </figure>
    </Col>
  );
};

export default GalleryCard;
