import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import PrimaryButton from "../../components/Primary_button";
import school from "../../assets/img/school.png";

export default function HeroSection() {
  return (
    <Container>
      <Row>
        <Col lg={6}>
          <div className="hero-contents">
            <div className="hero-text">
              <h1>Excellence, Our Top Priority </h1>
              <p>
                Modern Vision Academy is an award-winning school located in the
                heart of the Rural Municipality, Kanchan with a pristine and
                child friendly environment.
              </p>
            </div>

            <Link to="/contact">
              <PrimaryButton button_copy="Contact Us" />
            </Link>
          </div>
        </Col>
        {/* <div className="home-hero-content"></div> */}
        <Col lg={6}>
          <div className="home-hero-image">
            <figure>
              <img src={school} alt="images of child" />
            </figure>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
