import { useEffect } from "react";
import AboutBanner from "../../components/About/AboutBanner";
import AboutMessage from "../../components/About/AboutMessage";
import AboutVision from "../../components/About/AboutVision";
import ManagementCommitte from "../../components/About/ManagementCommitte";

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <section className="about">
      <AboutBanner />
      <AboutMessage />
      <AboutVision />
      <ManagementCommitte />
    </section>
  );
};

export default About;
