import { Col, Container, Row } from 'react-bootstrap';
import founderchairperson from '../../assets/img/founderchairperson.png';
import shareholder9 from '../../assets/img/shareholder9.png';
import MessageContainer from './MessageContainer';
import chairPerson from '../../assets/img/chairperson.png';
import managingDirector from '../../assets/img/managingDirector.png';
import ExChairPerson from '../../assets/img/exchair.jpg';

const AboutMessage = () => {
  return (
    <Container>
      <section className='about__message'>
        <Row className='justify-content-between'>
          <MessageContainer
            column='7'
            messagePerson='Founder Chair Person'
            message='Greetings to all Dear Guardians, Staff, Students and well-
                wishers It gives me immense pleasure to present Modern Vision
                Academy, a pioneer institution in education. Education today
                does not merely mean the cultivation of memory, which gives a
                student a technique to get jobs or to earn livelihood but also
                means a continuous process that makes him / her truly
                intelligent to spread the light of knowledge. There is no
                limitation to my gratification observing the school, established
                in my leadership, growing and transforming so rapidly and so
                smoothly. Wish its prosperous growth in future!'
          />
          <Col lg={4}>
            <div className='about__message--img'>
              <figure>
                <img src={founderchairperson} alt='Chair Person' />
              </figure>
            </div>
          </Col>
        </Row>
      </section>
      <section className='about__message'>
        <Row style={{ flexWrap: 'wrap' }}>
          <Col lg={4}>
            <div className='about__message--img about__message--img-principal '>
              <figure>
                <img src={shareholder9} alt='Chair Person' />
              </figure>
            </div>
          </Col>
          <MessageContainer
            column='7'
            messagePerson='Principal'
            message={
              <p style={{ margin: '0', lineHeight: '180%' }}>
                <i>A Journey of a thousand Miles Begins With a Single Step</i>
                <br />
                Welcome to the website of Modern Vision Academy, Kanchan - 3,
                Nepal. As the Principal of the school, I feel honoured and
                privileged to be part of an educational institution where the
                commitment of the school and the staff to the provision of an
                excellent all-round education for students is hugely impressive.
                As you navigate your way through the site discovering what we
                have to offer, you will find answers to the many preliminary
                questions you may have about our school, our academic programmes
                and extracurricular activities for the exposure of student's
                inherited qualities.
                <br /> We look at ourselves as a community of learners where
                everyone learns including our teachers, parents and staff. We
                strongly believe in upholding high standards with an absolute
                commitment to strive to understand and improve the educational
                process using team strategies, while wholly centering on
                student-achievement.
                <br /> We pride ourselves on creating a culture of learning
                where students are free to express or share their interest in
                variety of fields while developing their character as well. We
                seek to inculcate in our students the values of delayed
                gratification, integrity, civic consciousness and the
                willingness not to give up.
                <br /> Furthermore, achievement of ISO Certification of
                standardization 2015 has boosted up our dedication and
                commitment to enhance our quality-standard beyond the limit.
                Devotion to their assigned tasks shown by management body makes
                sure that no stone will be left unturned to magnify the
                achievement to a new dimension. So as the Principal I welcome
                you to join our family and experience the new ways of learning.
              </p>
            }
          />
        </Row>
      </section>
      <section className='about__message'>
        <Row className='justify-content-between'>
          <MessageContainer
            column='7'
            messagePerson='Chair Person'
            message='Huge Welcome & greeting to all parents, guardians, 
            well-wishers and students !
            It’s a moment of great pleasure and achievement  to put 
            forward my words as the Present Chair Person of MVA. 
            We worked hard with team spirit and achieved the top 
            most place in the sector of education in this region. Our 
            achievement has automatically transformed  into inspiration 
            and it is continuously pushing us ahead to be more dedicated,
            result oriented , self motivated, re-energetic. Achievement of ISO Certification 9001 2015 has added a feather to our crown.I believe each child is unique and has tremendous potential to learn. It is our responsibility as faculties and parents to mould them into flawless, zealous and victorious citizen.  I extend my warm wishes to the Managing Director, Principal, Staff and Students of   Modern Vision Academy to continue this journey on the road of excellence.'
          />
          <Col lg={4}>
            <div className='about__message--img'>
              <figure>
                <img src={chairPerson} alt='Chair Person' />
              </figure>
            </div>
          </Col>
        </Row>
      </section>
      <section className='about__message'>
        <Row className='justify-content-between' style={{ flexWrap: 'wrap' }}>
          <Col lg={4}>
            <div className='about__message--img about__message--img-principal '>
              <figure>
                <img src={managingDirector} alt='Chair Person' />
              </figure>
            </div>
          </Col>
          <MessageContainer
            column='7'
            messagePerson='Managing Director'
            message={
              <p style={{ margin: '0', lineHeight: '180% !important' }}>
                "A clear vision, backed by definite plans, gives one a
                tremendous feeling of confidence and personal power."-Brian
                Tracy
                <br />
                Dear Parents, Students and well-wishers, Greetings to you all
                from Modern Vision Academy, I feel exultant to say that "Modern
                Vision Academy" has been established as one of the leading
                institutions to provide quality education for play group to
                secondary level program. Since its establishment we are focusing
                on academic achievement of the highest order that is reinforced
                by all-round education and personality development.
                <br />
                We aim to empower our children in such a manner that they act as
                representatives of a meaningful and value-based society. Our
                pedagogy which is holistic and comprehensive complements this.
                We have a team of fabulous faculty members who display boundless
                energy and intense commitment which keeps the ethos of our
                school shining brightly.
                <br />
                Our curriculum and practice are constantly reviewed to ensure
                that we are following the most updated best practices meeting
                all regulations and addressing the future needs of our students.
                Our staff consistently follows current trends and after
                brain-storming over the best plan of action to incorporate those
                trends. We explore the possibilities in every individual child,
                hone/sharp their skills and make them capable in pursuit of
                worthy goals in the service of the Nation and humanity. Here
                comes the role of physical education, sports and games and
                co-curricular activities. A joyful experiential learning system
                is followed, where in each child is encouraged to participate
                whole heartedly.
                <br /> At the end I would like to say all the dear ones:
                character creates self respect, which in turn leads to high
                self-esteem. Maturity is reflected in all aspects of character.
                The decision we make the friends we choose and the
                responsibilities we accept. Always remember to uphold the
                dignity of people. Be always ready to give preference to others.
                What you are, is God's gift to you, what you become is your gift
                to God. Have something to live for. Bring out the best in you.
              </p>
            }
          />
        </Row>
      </section>
      <section className='about__message'>
        <Row className='justify-content-between'>
          <MessageContainer
            column='7'
            messagePerson='Former Managing Director'
            message='हालको कञ्चन गाउपालिका वडा नं. ३ तथा साविक रुद्रपुर गा.वि.स., वडा नं. ७ हरैयामा २०६६ सालमा स्थापना भई आप्नो यात्रा सुरु गरेको यस मोडर्न भिजन एकेडेमी हाल सम्म आइ पुग्दा विभिन्न प्रकारका उतार– चढाव, दुःख– कष्ट झेल्दै आप्नो यात्रा बढाइ रहेको छ । 
            स्थानीय शैक्षिक व्यक्तित्वहरु द्वरा स्थापित यस शिक्षण संस्था कञ्चन गाउपालिकाका अलावा छिमेकी गैंडहवा गाउपालिका, लुम्बिनी सांस्कृतिक न.पा. र कपिलवस्तु जिल्लाको वाणगंगा न.पा. का अभिभावक र विद्याथीहरुको रोजाई बन्न सफल भएको छ । विशाल भौतिक पूर्वाधार,   पर्याप्त खेल मैदान, लामो समय शिक्षण पेशामा आबद्ध शिक्षक शिक्षिकाहरुको अथक प्रयासले हालको अवस्था स्थापित भएको हो । 
            २०७० साल देखि एस.एल.सी. को प्रथम व्याच उत्पादन गर्न सफल यस संस्थाका उत्पादित विद्यार्थी भाई– बहिनीहरु नेपाल सरकार द्वारा सञ्चालित एम.बि.बि.एसमा पूर्ण छात्रवृतिमा अध्ययन गर्न सफल भएका छन । केही वि. फार्मा, स्टाफ नर्स, इन्जिनियरिङ्ग र आइ.सि.टि. विषयमा अध्ययनरत छन ।  केही विद्यार्थीहरु शिक्षक सेवा आयोग मा छनौट भइ विभिन्न विद्यालयमा अध्यापनरत रहेकाछन । सोका अलावा विभिन्न मित्रराष्ट्रहरुमा सञ्चालित विश्व विद्यालयहरुमा महत्वपूर्ण विषयमा स्नातक र स्नातकोत्तर तहमा अध्ययनरत रहेकाछन् । साथै निजामती कर्मचारी तर्प पनि केही विद्यार्थीहरु आबद्ध भएको देखिन्छ । 
            शान्त मनोरम विद्यालय परिसर, वालमैत्री शिक्षण सिकाई पद्धति, उच्च स्तरको सिकाई उपलब्धि, तालिम प्राप्त शिक्षक शिक्षिका, निरन्तर अनुगमन,  पर्याप्त कम्प्यूटर ल्याव र विज्ञान प्रयोगशाला, स्वच्छ पिउनेपानीको व्यवस्था, पुस्तकालयको समुचित व्यवस्थापन विभिन्न अतिरिक्त कृयाकलाप, ज्ञान सीपको उचित मुल्याङ्कन पर्याप्त शौचालय र हरियाली वातावरणले यो संस्था कञ्चन गाउपालिकाकै अव्वल दर्जाको शिक्षण संस्था हुन पुगेको छ । 
            यस अवसरमा विद्यालयको सर्वाङ्गिण मुल्याङ्कनमा आपूलाई उच्चकोटीमा राख्न सफल भइ (ISO 9001-2015 )  प्राप्त गर्न सफल भएकोमा यस मोडर्न भिजन एकेडेमी परिवारलाई हार्दिक बधाइ तथा शुभकामना व्यक्त गर्दछु । धन्यवाद ।  
          '
          />
          <Col lg={4}>
            <div className='about__message--img'>
              <figure>
                <img src={ExChairPerson} alt='Chair Person' />
              </figure>
            </div>
          </Col>
        </Row>
      </section>
    </Container>
  );
};

export default AboutMessage;
