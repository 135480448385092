import { Col, Container, Row } from "react-bootstrap";
import WhyusCard from "../../components/WhyusCard";
import computerclass from "../../assets/img/computerclass.png";
import classroom from "../../assets/img/classroom.png";
import classroom1 from "../../assets/img/classroom1.png";

const contents = [
  {
    img: computerclass,
    title: "Education with Experience",
    describe:
      "We believe that education is the most important aspect of growth and an educated population solves many problems. With this aspiration MVA has grown from brick to brick and is currently nurturing more than 800 students. Main purpose of this facility is that we as a leading institution  receiving  through society so it's our major concern to give back to the society our stake holders. Our school plays an instrumental role in imparting quality education to thousands students every year.  We believe each child is unique and has tremendous  potential to learn. It is our responsibility as faculties and parents to mould them into a flawless, zealous and victorious citizen.",
  },
  {
    img: classroom,
    title: "Education in practical way",
    describe:
      "Understanding the need of hour the school has switched over to impart education  more in practical way. The school believes that education is the holistic process of growing up and developing one's faculties. It is the art of shaping the individual's personality through physical, emotional, intellectual and spiritual develop ment. The purpose of education is to teach the students their duty to their fellow men and to the community and country at large, to infuse them with a desire and energy to fulfill their duty to all powers. Therefore, we have shifted our focus to practical than theoretical teaching classes. In primary and pre-primary classes we have applied Montessori based education. In upper classes too students are encouraged to gain practical knowledge, skill not merely rote learning, so that whatever they learn will guide them in their future endeavour.",
  },
  {
    img: classroom1,
    title: "Best and most-sought institution in the region",
    describe:
      "We feel proud of our quality and creative education by equipping our students with skill, confidence and a positive approach with all-round development. The school is relentlessly striving to perceive and maintain academic excellence. At amiable environment MVA nurtures creativity, passion, resilience and leadership quality among students for development of versatility, personality and are able to meet the challenges of our society with confidence. For providing effective education the school uses audio-visual classes in pre-primary and primary classes. Well trained teachers teach focusing on individual intelligence level which makes learning effective and long lasting.",
  },
];

const cards = contents.map((content, index) => {
  return (
    <Col lg={4} sm={6}>
      <WhyusCard
        key={index}
        img={content.img}
        title={content.title}
        description={content.describe}
      />
    </Col>
  );
});

export default function Whyus() {
  return (
    <div className="why-us">
      <Container>
        <h2>Why us ?</h2>
        <p>
          A curiosity 'Why US' is a parameter which is pushing us ahead to reach
          the new dimension in the world of school education. We believe in Hard
          work, Discipline & quality education. To fulfill our goals and
          objectives we are always ready to improve our strategies . We provide
          Digital learning platform where we teach students with interactive
          techniques. Along with study and sports, we also emphasis on
          developing strong personality both morally and physically. We provide
          new and improved techniques to inherit personality traits. Here at
          Modern Vision Academy we are developing future generations with
          positive mindset of responsibilities, discipline and trustworthiness.
          Reaching out to the community has always been an important part of
          MVA's philosophy. The MVA family, founders strongly believe that, a
          child without holistic education is like a bird without wings. The
          motive behind establishing MVA was to ensure that the children of this
          remote area will not have to compromise on the quality of education,
          empower children of our community and society at large. With the
          modest start of 350 children, MVA stands tall with more than 8oo
          children under its banner. Today MVA is recognised as one of the best
          schools in the region with faculty and facilities that impart quality
          education and empower students to compete shoulder-to-shoulder with
          their counterparts in higher level eg MBBS, Engineering etc in Nepal
          and abroad. In the words of <i>Nelson Mandela</i>, "Education is the
          most powerful weapon we can use to change the world." This resonates
          with the vision and motto of MVA, "Knowledge is Power."
        </p>
        <Row className="why-us-card-collection"> {cards}</Row>
      </Container>
    </div>
  );
}
