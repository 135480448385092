import { useState } from "react";
import { Container } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import NavMobile from "./NavMobile";

const Navbar = () => {
  const [enableMenu, setEnableMenu] = useState(false);

  const enableMenuHandler = () => {
    setEnableMenu((prev) => !prev);
  };
  return (
    <nav className="my-navbar">
      <Container>
        <div className="my-navbar__main">
          <Link to="/">
            <img
              src="./Logo.png"
              alt="Modern vision academy"
              className="logo"
            />
          </Link>
          <nav className="nav">
            <NavLink
              to="/"
              className={(navData) =>
                navData.isActive ? "active nav-links" : "nav-links"
              }
              // className={`nav-links ${activePage === "home" && "active"}`}
            >
              Home
            </NavLink>
            <NavLink
              to="/facilities"
              className={(navData) =>
                navData.isActive ? "active nav-links" : "nav-links"
              }
            >
              Facilities
            </NavLink>
            <NavLink
              to="/gallery"
              className={(navData) =>
                navData.isActive ? "active nav-links" : "nav-links"
              }
            >
              Gallery
            </NavLink>
            <NavLink
              to="/about"
              className={(navData) =>
                navData.isActive ? "active nav-links" : "nav-links"
              }
            >
              About
            </NavLink>
          </nav>
          <Link to="/contact">
            <button className="bton--primary contact-bton">Contact</button>
          </Link>
          <svg
            className="list"
            viewBox="0 0 12 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={enableMenuHandler}
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0.5 9C0.5 8.86739 0.552679 8.74021 0.646447 8.64645C0.740215 8.55268 0.867392 8.5 1 8.5H11C11.1326 8.5 11.2598 8.55268 11.3536 8.64645C11.4473 8.74021 11.5 8.86739 11.5 9C11.5 9.13261 11.4473 9.25979 11.3536 9.35355C11.2598 9.44732 11.1326 9.5 11 9.5H1C0.867392 9.5 0.740215 9.44732 0.646447 9.35355C0.552679 9.25979 0.5 9.13261 0.5 9ZM0.5 5C0.5 4.86739 0.552679 4.74021 0.646447 4.64645C0.740215 4.55268 0.867392 4.5 1 4.5H11C11.1326 4.5 11.2598 4.55268 11.3536 4.64645C11.4473 4.74021 11.5 4.86739 11.5 5C11.5 5.13261 11.4473 5.25979 11.3536 5.35355C11.2598 5.44732 11.1326 5.5 11 5.5H1C0.867392 5.5 0.740215 5.44732 0.646447 5.35355C0.552679 5.25979 0.5 5.13261 0.5 5ZM0.5 1C0.5 0.867392 0.552679 0.740215 0.646447 0.646447C0.740215 0.552679 0.867392 0.5 1 0.5H11C11.1326 0.5 11.2598 0.552679 11.3536 0.646447C11.4473 0.740215 11.5 0.867392 11.5 1C11.5 1.13261 11.4473 1.25979 11.3536 1.35355C11.2598 1.44732 11.1326 1.5 11 1.5H1C0.867392 1.5 0.740215 1.44732 0.646447 1.35355C0.552679 1.25979 0.5 1.13261 0.5 1V1Z"
              fill="black"
            />
          </svg>
        </div>
        {/* <nav className="nav__small">
          <Link to="/" className="nav-links">
            Home
          </Link>
          <Link to="/facilities" className="nav-links">
            Facilities
          </Link>
          <Link to="gallery" className="nav-links">
            Gallery
          </Link>
          <Link to="about" className="nav-links">
            About
          </Link>
        </nav> */}
        {enableMenu && <NavMobile onClick={enableMenuHandler} />}
      </Container>
    </nav>
  );
};

export default Navbar;
