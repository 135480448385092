import { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";

import GalleryCard from "../../components/Gallery/GalleryCard";
import Query from "../../utils/Query";
import GET_GALLERY from "../../queries/gallery/getGallery";

const Loader = () => {
  return (
    <div className="gallery_loader">
      <p>Please Wait...</p>
    </div>
  );
};

const Gallery = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [galleryItem, setGalleryItem] = useState(-1);
  return (
    <section className="gallery">
      <Container>
        <section className="gallery__header">
          <h2>Gallery</h2>
          <small>Explore the world of Modern Vision Academy</small>
        </section>

        <Query query={GET_GALLERY} loader={<Loader />}>
          {({
            data: {
              galleries: { nodes },
            },
          }) => {
            return (
              <>
                <section className="gallery__header">
                  {/* <h2>Gallery</h2>
                <small>Explore the world of Modern Vision Academy</small> */}
                  <div className="gallery__header--action">
                    <button
                      className={`gallery__header--button ${
                        galleryItem === -1 && "active"
                      }`}
                      onClick={(e) => {
                        e.preventDefault();
                        setGalleryItem(-1);
                      }}
                    >
                      All
                    </button>
                    {nodes.map((entry, key) => (
                      <button
                        key={entry.slug}
                        className={`gallery__header--button ${
                          galleryItem === key && "active"
                        }`}
                        onClick={(e) => {
                          e.preventDefault();
                          setGalleryItem(key);
                        }}
                      >
                        {entry.galleyGroup.title}
                      </button>
                    ))}
                  </div>
                </section>
                <section className="gallery__grid">
                  <Row style={{ width: "100%" }}>
                    {galleryItem < 0
                      ? nodes.map((e) =>
                          e.galleyGroup.gallery.map((g, i) => (
                            <GalleryCard key={i} image={g.sourceUrl} />
                          ))
                        )
                      : nodes[galleryItem].galleyGroup.gallery.map((g, i) => {
                          return <GalleryCard key={i} image={g.sourceUrl} />;
                        })}
                  </Row>
                </section>
              </>
            );
          }}
        </Query>
      </Container>
    </section>
  );
};

export default Gallery;
