import { useEffect, useRef, useState } from 'react';
import { Col } from 'react-bootstrap';

const MessageContainer = ({ messagePerson, message, column }) => {
  const [showFullText, setShowFullText] = useState(false);

  const toggleShowFullText = () => {
    setShowFullText(!showFullText);
  };
  const [numLines, setNumLines] = useState(0);

  const paragraphRef = useRef(null);

  useEffect(() => {
    if (paragraphRef.current) {
      const { height, lineHeight } = window.getComputedStyle(
        paragraphRef.current
      );
      const parsedHeight = parseInt(height, 10);
      const parsedLineHeight = parseInt(lineHeight, 10);

      setNumLines(Math.round(parsedHeight / parsedLineHeight));
    }
  }, []);
  return (
    <Col lg={column}>
      <div className='about__message--content'>
        <h2>Message from {messagePerson}</h2>
        <p
          ref={paragraphRef}
          className={`${
            showFullText
              ? 'about__message--content__para'
              : 'about__message--content__para about__message--content-overflow'
          }`}
        >
          {message}
        </p>
        {numLines > 11 && (
          <button
            onClick={toggleShowFullText}
            className='about__message--content-button'
          >
            {showFullText ? 'Read Less' : 'Read More'}
          </button>
        )}
      </div>
    </Col>
  );
};

export default MessageContainer;
