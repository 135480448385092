import React, { useEffect, useState } from "react";

const ScrollButton = () => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 500) {
      setVisible(true);
    } else if (scrolled <= 500) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
      /* you can also use 'auto' behaviour
		in place of 'smooth' */
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisible);

    return () => {
      window.removeEventListener("scroll", toggleVisible);
    };
  }, []);
  return (
    <div>
      <div
        onClick={scrollToTop}
        className={visible ? "scrolltop" : "notVisible"}
      >
        <svg
          width="16"
          height="8"
          viewBox="0 0 16 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.19313 0.292912C7.63941 -0.0976372 8.36416 -0.0976372 8.81044 0.292912L15.6653 6.29174C16.1116 6.68229 16.1116 7.31654 15.6653 7.70709C15.219 8.09764 14.4943 8.09764 14.048 7.70709L8 2.41437L1.95202 7.70396C1.50575 8.09451 0.780988 8.09451 0.334709 7.70396C-0.11157 7.31342 -0.11157 6.67916 0.334709 6.28861L7.18956 0.289787L7.19313 0.292912Z"
            fill="white"
          />
        </svg>
      </div>
    </div>
  );
};

export default ScrollButton;
