import Facilites1 from "../../components/Facilites1";
import Facilitiies2 from "../../components/Facilites2";
import Banner from "../../components/Banner";
import { useEffect } from "react";

const Facilities = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="facilities">
      <Banner
        bannerImg={"facilitiesBanner.jpg"}
        title={"Our Facilities"}
        description={"Explore all facilities"}
      />
      <div className="facilities_collection">
        <Facilitiies2
          img={"schoolbus.jpg"}
          title={"School Bus"}
          description={
            "The school has a fleet of 3 buses and a van which gives service to the small children coming from distant areas of this rural municipality and nerighbouring district and municipalities at cheapest charge. The punctuality of service and child-friendly behaviour of the staff has added popularity."
          }
        />
        <Facilites1
          img={"canteen.jpg"}
          title={"Canteen"}
          description={
            "From the very beginning of the establishment of the institute , there has been a canteen run in side the school premises  with healthy and delicious breakfast at reasonable price. Junk foods are totally banned not only in canteen but also in tiffin from home."
          }
        />
        <Facilitiies2
          img={"./facilites.png"}
          title={"Boundary"}
          description={
            "The school compound is surrounded by concrete wall with a side gate and the main gate. The wall has  made the school safe for all students from class Montessori to ten. Not only the infrastructure but plants and garden has been made safe and secure.  The greenery makes the premises very joyful for learning."
          }
        />
        <Facilites1
          img={"playground.jpg"}
          title={"Playground"}
          description={
            "Although Modern Vision Academy is run in private sector, it is rich in infrastructure and playing facility. It has its own big play ground where almost all games and sports are conducted. Students get chance to develop their pursuit of favourite games under the supervision of expert game-teachers."
          }
        />
        <Facilitiies2
          img={"coaching.jpg"}
          title={"Coaching classes"}
          description={
            "We provide coaching/extra classes for SEE students, hostel for SEE students, extra classes for weak students and counseling for distracted students."
          }
        />
      </div>
    </div>
  );
};

export default Facilities;
