import { Col, Container, Row } from "react-bootstrap";
import { useEffect, useState } from "react";

const Contact = () => {
  const initialValues = {
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    message: "",
  };
  const [addSuccess, setAddSuccess] = useState(null);
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setAddSuccess(validate(formValues));
  };

  const validate = (value) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    if (!value.firstname) {
      errors.firstname = "Firstname is required";
    }
    if (!value.lastname) {
      errors.lastname = "Lastname is required";
    }
    if (!value.email) {
      errors.email = "Email is required";
    } else if (!regex.test(value.email)) {
      errors.email = "This is not a valid format";
    }
    if (!value.phone) {
      errors.phone = "Phone no. is required";
    }
    setFormErrors(errors);
    for (let key in errors) {
      if (errors[key]) {
        return false;
      }
    }
    return true;
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <section className="contact">
      <Container>
        <Row>
          <Col lg={5}>
            <form className="contact-form" onSubmit={handleSubmit}>
              <h2>Contact Us</h2>
              <small>
                Drop your details and we will get back to you shortly.
              </small>
              <div className="contact-form__main">
                <div className="contact-form__main--row">
                  <div className="contact-form__item">
                    <label>First Name</label>
                    <input
                      type="text"
                      placeholder="Eg. John"
                      name="firstname"
                      value={formValues.firstname}
                      onChange={handleChange}
                    />
                    <span>{formErrors.firstname}</span>
                  </div>
                  <div className="contact-form__item">
                    <label>Last Name</label>
                    <input
                      type="text"
                      placeholder="Eg. Luffy"
                      name="lastname"
                      value={formValues.lastname}
                      onChange={handleChange}
                    />
                    <span>{formErrors.lastname}</span>
                  </div>
                </div>
                <div className="contact-form__main--row">
                  <div className="contact-form__item">
                    <label>Email</label>
                    <input
                      type="email"
                      placeholder="example@gmail.com"
                      name="email"
                      value={formValues.email}
                      onChange={handleChange}
                    />
                    <span>{formErrors.email}</span>
                  </div>
                  <div className="contact-form__item">
                    <label>Phone No.</label>
                    <input
                      type="text"
                      placeholder="Eg. 981234567"
                      name="phone"
                      value={formValues.phone}
                      onChange={handleChange}
                    />
                    <span>{formErrors.phone}</span>
                  </div>
                </div>

                <div className="contact-form__item">
                  <label>Message</label>
                  <textarea
                    rows="5"
                    className="contact-form__item--message"
                    type="text"
                    placeholder="Please write your message here."
                    name="message"
                    value={formValues.message}
                    onChange={handleChange}
                  />
                  <span>{formErrors.message}</span>
                </div>
              </div>
              <button className="bton--primary submit">Submit</button>
            </form>
          </Col>
          <Col lg={7}>
            <div className="contact-map">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3535.8535005950257!2d83.2509006!3d27.598071200000014!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39968d6cf587fe37%3A0x4a81db94a54d0e05!2sModern%20Vision%20School!5e0!3m2!1sen!2snp!4v1671776566398!5m2!1sen!2snp"
                allowfullscreen=""
                loading="lazy"
                title="map"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Contact;
