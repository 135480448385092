import React from "react";
import { Container } from "react-bootstrap";

export default function IsoCertification() {
  return (
    <Container>
      <div className="iso-certification">
        <h2>ISO 9001 Certification</h2>
        <p>
          Modern Vision aims to enhance student’s satisfaction through the
          effective application of the system, including processes for
          improvement of the system and the assurance of conformity to customer
          and applicable statutory and regulatory requirements.It is ISO
          9001:2015 and ISO 22000:2018 certified.
        </p>
        <div className="certification-icons">
          <img src="./iso9001.png" alt="iso 9001" />
          <img src="./iso22000.png" alt="iso 22000" />
        </div>
      </div>
    </Container>
  );
}
